




















import {Vue, Component, Prop} from 'vue-property-decorator';
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateAnnualReportPayload from "@/dto/archive/CreateAnnualReportPayload";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {namespace} from "vuex-class";
import DocumentService from "@/services/DocumentService";
import {processError} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  computed: {
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {SingleFileHolder, AutoComplete, PortalInput, SimpleCompanySearch}
})
export default class CreateAnnualReportModal extends Vue {

  @AppModule.Action private startLoading!: () => void;
  @AppModule.Action private stopLoading!: () => void;

  payload = new CreateAnnualReportPayload();

  @Prop()
  onSaved!: () => void

  companyChanged(company: CompanyDTO){
    this.payload.companyId = company.id;
  }

  get isFilled(): boolean {
    return !!this.payload.companyId && !!this.payload.type && !!this.payload.year && !!this.payload.state && !!this.payload.file;
  }

  save() {
    this.startLoading();
    DocumentService.createAnnualReport(this.payload).then(
        ok => {
          this.stopLoading();
          this.onSaved();
          this.$modal.hideAll();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }
}
