






































import {Vue, Component} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import AnnualReportDTO from "@/dto/archive/AnnualReportDTO";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import DocumentService from "@/services/DocumentService";
import {processError} from "@/utils/ComponentUtils";
import CreateAnnualReportPayload from "@/dto/archive/CreateAnnualReportPayload";
import CreateAnnualReportModal from "@/components/documents/CreateAnnualReportModal.vue";


const AppModule = namespace("App");

@Component({
  components: {PaginationComponent}
})
export default class AnnualReportList extends Vue {

  @AppModule.Action private startLoading!: () => void;
  @AppModule.Action private stopLoading!: () => void;

  reports: Array<AnnualReportDTO> = [];
  totalPages = 1;
  pageNumber = 1;

  createPayload = new CreateAnnualReportPayload();

  mounted(){
    this.loadReports();
  }


  loadReports(){
    this.startLoading();
    DocumentService.getAnnualReports(this.pageNumber, 20).then(
        ok => {
          this.reports = ok.data.content;
          this.totalPages = ok.data.totalPages;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  changePage(page: number) {
    this.pageNumber = page;
    this.loadReports();
  }

  newReport(){
    this.$modal.show(
        CreateAnnualReportModal,
        {
          onSaved: () => this.reset()
        }
    )
  }

  reset(){
    this.totalPages = 1;
    this.pageNumber = 1;
    this.loadReports();
  }




}
