import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class CreateAnnualReportPayload {
    companyId!: number;
    type!: string;
    year!: number;
    state!: string;
    file!: FileMetaDTO;

    constructor(init?: Partial<CreateAnnualReportPayload>) {
        Object.assign(this, init);
    }
}